import axios from 'axios';

import { categories } from '../constants/categories'
import { subcategories } from '../constants/subcategories'

const startupsUrl = "startups/";
const userStartupUrl = "startups/user/";
const startupRequestUrl = "request/";
const contactUrl = "contact";
const newsletterURl = "newsletter";

export default class Api {

  static networkError = 0;

  static getStartups = async (filters = { page: 0 }, featured = false) => {
    let newFilters = { ...filters };
    try {
      if (Object.keys(newFilters).find((filter) => filter == "category") != undefined) {
        newFilters["category"] = categories.filter((category) => category.id == newFilters["category"])[0].name;
      }

      if (Object.keys(newFilters).find((filter) => filter == "subcategory") != undefined) {
        newFilters["subcategory"] = subcategories.filter((subcategory) => subcategory.id == newFilters["subcategory"])[0].name;
      }

      const processedFilters = Object.keys(newFilters).map(key => key + '=' + newFilters[key]).join('&');

      let featuredUrl = "";
      if (featured) {
        featuredUrl = "featured/";
      }

      const response = await axios.get(`${process.env.NEXT_PUBLIC_URL}${startupsUrl}${featuredUrl}?${processedFilters}`);
      return [response.data, undefined];
    } catch (error) {
      return [undefined, error];
    }
  }

  static getStartup = async (id) => {
    try {
      const response = await axios.get(`${process.env.NEXT_PUBLIC_URL}${startupsUrl}${id}/`);
      return [response.data, undefined];
    } catch (error) {
      return [undefined, error];
    }
  }

  static getUserStartup = async (userId) => {
    try {
      const response = await axios.get(`${process.env.NEXT_PUBLIC_URL}${userStartupUrl}${userId}/`);
      return [response.data, undefined];
    } catch (error) {
      return [undefined, error];
    }
  }

  static requestStartup = async (id, email) => {
    try {
      const response = await axios.post(`${process.env.NEXT_PUBLIC_URL}${startupsUrl}${id}/${startupRequestUrl}`);
    } catch (error) {
      return
    }
  }

  static patchStartup = async (startupData, idToken) => {
    try {
      const response = await axios.put(`${process.env.NEXT_PUBLIC_URL}${startupsUrl}${startupData.id}`, startupData, {
        headers: {
          'Authorization': idToken
        }
      });
      return [response, undefined];
    } catch (error) {
      return [undefined, error];
    }
  }

  static postStartup = async (startupData, idToken) => {
    try {
      const response = await axios.post(`${process.env.NEXT_PUBLIC_URL}${startupsUrl}`, startupData, {
        headers: {
          'Authorization': idToken
        }
      });
      return [response, undefined];
    } catch (error) {
      return [undefined, error];
    }
  }

  static requestStartup = async (payload) => {
    try {
      await axios.post(`${process.env.NEXT_PUBLIC_URL}${startupsUrl}${payload.id}/${startupRequestUrl}`, payload);
      return undefined;
    } catch (error) {
      return error;
    }
  }

  static sendContactMessage = async (userInput) => {
    try {
      await axios.post(`${process.env.NEXT_PUBLIC_URL}${contactUrl}`, userInput);
      return undefined;
    } catch (error) {
      return error;
    }
  }

  static addContact = async (email) => {
    try {
      const response = await axios.post(`${process.env.NEXT_PUBLIC_URL}${newsletterURl}`, email);
      return [response, undefined];
    } catch (error) {
      return [undefined, error];
    }
  }

}
