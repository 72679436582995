export const subcategories = [
  { id: 1, name: 'Captación de leads', image: '/png/categories/captacion-leads.png', category: 1 },
  { id: 2, name: 'Consultoría', image: '/png/categories/consultoria.png', category: 1 },
  { id: 3, name: 'Construtech', image: '/png/categories/construtech.png', category: 2 },
  { id: 4, name: 'Valoraciones/Tasaciones', image: '/png/categories/valoracionestasaciones.png', category: 3 },
  { id: 5, name: 'Análisis/Investigación', image: '/png/categories/analisisinvestigación.png', category: 3 },
  { id: 6, name: 'Ratings', image: '/png/categories/ratings.png', category: 3 },
  { id: 7, name: 'Geolocalización', image: '/png/categories/geolocalizacion.png', category: 3 },
  { id: 8, name: 'Herramientas y servicios para agentes', image: '/png/categories/herraminetas-y-servicios-para-agentes.png', category: 4 },
  { id: 9, name: 'Alquileres', image: '/png/categories/alquileres.png', category: 4 },
  { id: 10, name: 'Activos', image: '/png/categories/activos.png', category: 4 },
  { id: 11, name: 'Comunidades', image: '/png/categories/comunidades.png', category: 4 },
  { id: 12, name: 'Cerraduras', image: '/png/categories/cerraduras.png', category: 5 },
  { id: 13, name: 'Domótica', image: '/png/categories/domotica.png', category: 5 },
  { id: 14, name: 'Gestión energética', image: '/png/categories/gestion-energetica.png', category: 5 },
  { id: 15, name: 'Financiación Hipotecas', image: '/png/categories/financiacion-hipotecas.png', category: 6 },
  { id: 16, name: 'Financiación Crowdfounding', image: '/png/categories/financiacion-crowdfounding.png', category: 6 },
  { id: 17, name: 'Seguros del hogar', image: '/png/categories/seguros-del-hogar.png', category: 6 },
  { id: 18, name: 'Gestión de préstamos', image: '/png/categories/gestion-de-prestamos.png', category: 6 },
  { id: 19, name: 'Datos y valoraciones', image: '/png/categories/datos-y-valoraciones.png', category: 6 },
  { id: 20, name: 'Financiación alternativa', image: '/png/categories/financiacion-alternativa.png', category: 6 },
  { id: 21, name: 'Inversión de capital', image: '/png/categories/inversion-de-capital.png', category: 6 },
  { id: 22, name: 'Préstamos digitales', image: '/png/categories/prestamos-digitales.png', category: 6 },
  { id: 23, name: 'Bancos digitales', image: '/png/categories/bancos-digitales.png', category: 6 },
  { id: 24, name: 'Firma digital', image: '/png/categories/firma-digital.png', category: 7 },
  { id: 25, name: 'Agencia', image: '/png/categories/agencia.png', category: 7 },
  { id: 26, name: 'Servicios de Mantenimiento', image: '/png/categories/servicios-de-mantenimiento.png', category: 8 },
  { id: 27, name: 'Hospitality', image: '/png/categories/hospitality.png', category: 8 },
  { id: 28, name: 'Reformas', image: '/png/categories/reformas.png', category: 8 },
  { id: 29, name: 'Búsqueda de vivienda', image: '/png/categories/busqueda-de-vivienda.png', category: 9 },
  { id: 30, name: 'Búsqueda otros espacios', image: '/png/categories/busqueda-otros-espacios.png', category: 9 },
  { id: 31, name: 'Espacios flexibles y coworkings', image: '/png/categories/espacios-flexibles-y-coworkings.png', category: 9 },
  { id: 32, name: 'Alquiler P2P', image: '/png/categories/alquiler-p2p.png', category: 9 },
  { id: 33, name: 'Ventas P2P', image: '/png/categories/ventas-p2p.png', category: 9 },
  { id: 34, name: 'Tokenización', image: '/png/categories/tokenizacion.png', category: 6 },
  { id: 35, name: 'Coliving', image: '/png/categories/coliving.png', category: 9 },
  { id: 36, name: 'Otros', image: '/png/categories/otros.png', category: 9 },
  { id: 37, name: 'Tours virtuales', image: '/png/categories/tours-virtuales.png', category: 10 },
  { id: 38, name: 'Visualización 3D', image: '/png/categories/visualización-3d.png', category: 10 },
  { id: 39, name: 'Realidad virtual', image: '/png/categories/realidad-virtual.png', category: 10 },
  { id: 40, name: 'Herramientas para Planos y Diseño', image: '/png/categories/herramientas-para-planos-y-diseno.png', category: 10 },
  { id: 41, name: 'Logística', image: '/png/categories/logistica.png', category: 11 },
  { id: 42, name: 'Consultoría', image: '/png/categories/consultoria.png', category: 11 },
  { id: 43, name: 'CRM Inmobiliario', image: '/png/categories/CRM-inmobiliario.png', category: 1 }
]
