export const categories = [
  { id: 9, name: 'Marketplace', image: '/png/categories/Marketplace.png', featured: true },
  { id: 4, name: 'Gestión', image: '/png/categories/Gestion.png', featured: true },
  { id: 3, name: 'Data Solutions', image: '/png/categories/Data-Solutions.png', featured: true },
  { id: 6, name: 'Inversión y Financiación', image: '/png/categories/Inversión-y-Financiación.png', featured: true },
  { id: 1, name: 'Agencia', image: '/png/categories/Agencia.png', featured: true },
  { id: 10, name: 'Visual', image: '/png/categories/Visual.png', featured: true },
  { id: 2, name: 'Construtech', image: '/png/categories/Construtech.png', featured: false },
  { id: 5, name: 'Hogares inteligentes', image: '/png/categories/Hogares-inteligentes.png', featured: false },
  { id: 7, name: 'Legal', image: '/png/categories/legal.png', featured: false },
  { id: 8, name: 'Mantenimiento y Reformas', image: '/png/categories/Mantenimiento.png', featured: false },
  { id: 11, name: 'Otros', image: '/png/categories/otros.png', featured: false },
]
