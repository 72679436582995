import { useState, forwardRef } from 'react';

import MuiAlert from '@mui/material/Alert';

function useSnackbarHook() {
    const [alertState, setAlertState] = useState({ severity: 'error', message: '', showAlert: false });

    const Alert = forwardRef(function Alert(props, ref) {
        return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
    });

    const handleError = (error) => {
        setAlertState({ ...alertState, message: error.message, showAlert: true, severity:'error' });
    }

    return { Alert: Alert, alertState: alertState, setAlertState: setAlertState, handleError: handleError };
}

export default useSnackbarHook;