import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import useNextRouterHook from '../../hooks/useNextRouterHook';

export default function CookiesBanner() {
    const routerTo = useNextRouterHook();
    const { t } = useTranslation();
    const agreementLocalStorageKey = 'cookiesAgreed';
    
    const hideBanner = () => {
        const banner = document.getElementsByClassName('cookies-banner')[0];
        banner.classList.remove('active');
        banner.classList.add('hide');
    }

    const agreeToCookies = () => {
        localStorage.setItem(agreementLocalStorageKey, 'true');
    }

    useEffect(() => {
        const isCookiesAgreed = localStorage.getItem(agreementLocalStorageKey);
        const banner = document.getElementsByClassName('cookies-banner')[0];
        if (isCookiesAgreed) {
            banner.classList.remove('active');
            banner.classList.add('display-none');
        }
    }, [])


    return (
        <div className='cookies-banner active'>
            <div className="cookies-banner-title">{t('legals.cookies_banner.title')}</div>
            <p className="cookes-banner-text">{t('legals.cookies_banner.p1')}</p>
            <button className="action-button" onClick={() => { hideBanner(); agreeToCookies() }}>{t('legals.cookies_banner.agree')}</button>
            <button className="action-button" onClick={() => { hideBanner(); routerTo('/politicas/cookies') }}>{t('legals.cookies.title')}</button>
        </div>
    )
}
